@import '../../../../../../../styles/base/settings';

.perfil_main {
    padding: 10px;
    //margin-bottom: 160px;
}

.perfil_section {
    margin: 0 auto;
    max-width: 800px;
}

.perfil_container {
    //text-align: center;
    height: 350px;
    position: relative;
}

.perfil_logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -20%);
}

.perfil_container_icon {
    width: 250px;
}

.perfil_container_title {
    font-size: $font-titulo-principal;
    text-align: center;
}

.perfil_container_subtitle {
    font-size: $font-subtitulo-chico;
    text-align: center;
}

.perfil_datos1 {
    border-radius: 5px;
    margin-bottom: 40px;
    padding-left: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: 180px 1fr;
    font-size: $font-texto-grande;
    p {
        font-weight: 800;
        color: $color-azul;
    }
}

.perfil_datos2 {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    font-size: $font-texto-grande;
    margin-bottom: 40px;
}

.perfil_subtitulos {
    margin-bottom: 10px;
    color: $color-principal;
    font-size: $font-descripcion-precio;
    font-weight: 500;
}

.perfil_datos {
    padding: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: 180px 1fr 70px;
    grid-template-areas: "texto   dato   opcion";
}
.perfil_datos_texto {
    grid-area: texto;
    font-weight: 800;
    color: $color-azul;
}
.perfil_datos_dato {
    grid-area: dato;
}
.perfil_datos_opcion {
    grid-area: opcion;
}

@media (max-width: 780px) {
    .perfil_main {
        padding: 0px;
        margin: 0px -5px 0px -18px;
        margin-bottom: 0px;
    }
    .perfil_container {
        overflow: hidden;
    }
    .perfil_container_icon {
        width: 160px;
    }
    .perfil_container_title {
        font-size: 30px;
    }
    .perfil_container_subtitle {
        font-size: 15px;
    }
    .perfil_datos1 {
        grid-template-columns: 1fr;
        margin-bottom: 10px;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .perfil_datos {
        grid-template-columns: 1fr 70px;
        grid-template-areas: "texto   opcion"
                             "dato    opcion";
    }
    .perfil_datos_opcion {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 300px) {
    .perfil_main {
        margin: 0px -15px 0px -18px;
    }
    .perfil_container {
        height: 270px;
    }
    .perfil_logo {
        top: 58%;
    }
    .perfil_subtitulos {
        font-size: 18px;
    }
    .perfil_datos1 {
        p {
            font-size: 15px;
        }
    }
    .perfil_datos1 {
        div {
            font-size: 16px;
        }
    }
    .perfil_datos {
        padding: 10px;
    }
    .perfil_datos_texto {
        font-size: 15px;
    }
    .perfil_datos_dato {
        font-size: 14px;
    }
}