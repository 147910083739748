@import '../../../styles/base/settings';

.register_principal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px 50px 0px;
}

.register_grid {
    box-shadow: 1px 3px 10px rgb(0, 0, 0, 0.25);
    width: 450px;
}
.register_header {
    display: flex;
    background-color: black;
    color: white;
    font-size: 29px;
    font-weight: 500;
    padding: 20px;
    h4{
        margin-top: 4px;
    }
}
.register_stepper {
    padding: 20px 40px 40px 40px;
}

.register_textfield {
    padding-top: 20px;
}
.register_form_checkbox {
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
}

.aviso_privacidad_titulo {
    padding: 60px 60px 30px 60px;
    display: flex
}
.aviso_privacidad_container {
    padding: 0px 70px 70px 70px;
}
.aviso_privacidad_contenedores {
    margin-top: 40px;
}
.aviso_privacidad_subtitulos {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10px;
}

@media (max-width: 700px ) {
    .aviso_privacidad_titulo {
        padding: 30px 30px 30px 30px;
    }
    .aviso_privacidad_container {
        padding: 0px 30px 30px 30px;
    }
}

@media (max-width: 466px) {
    .register_principal {
        padding: 0px 0px 0px 0px;
    }
}

@media (max-width: 400px) {
    .aviso_privacidad_subtitulos {
        font-size: 17px;
    }
    .aviso_privacidad_contenedores {
        margin-top: 20px;
    }
}