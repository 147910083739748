@import '../../../styles/base/settings';

.principal_main {
    display: flex;
}
.principal_dark {
    background-color: $primary-black;
}
.principal_light {
    background-color: $primary-white;
}

.modal_main {
    height: 100%;
    padding: 10px;

    overflow-y: scroll;
    overflow-x: hidden;
}
.modal_light_theme{
    background-color: $primary-white;
    color: $text-color-light;
}
.modal_dark_theme{
    background-color: $primary-black;
    color: $text-color-dark;
}
.modal_title {
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 25px;
    font-size: $font-big;
}
.modal_titleicon {
    display: flex;
    color: $alert-red;
}
.modal_titleicon p {
    padding-top: 8px;
    padding-left: 8px;
}
.modal_close {
    align-self: end;
}

.messagemodal_message{
    width: 500px;
}
.messagemodal_title{
    display: flex;
    color: $alert-blue;
}
.messagemodal_title p{
    padding-left: 8px;
    padding-top: 13px;
    height: 15px;
}
.messagemodal_text {
    padding: 5px;
    display: block;
    text-align: center;
}


.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;    
}