@import '../../../../../styles/base/settings';

.sidebar{
    // height: calc(100vh - 100px);
    // height: 100px;
    position: relative;
    //box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.15);
}
.sidebar_main {    
    display: flex;
    position: relative;
    //Eevitar seleccionar elementos
    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
.sidebar_notification{
    position: relative;
    //Eevitar seleccionar elementos
    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.sidebar_notification_online{
    margin-left: 10px;
    height: 20px;
    display: flex;
}
.sidebar_notification_icon{
    min-width: 40px;
    height: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    white-space: nowrap;
}
.sidebar_icon_on{
    color: $alert-green;
}
.sidebar_icon_off{
    color: $alert-red;
}

.sidebar_notification_text{
    padding-left: 8px;
    padding-top: 3px;
    font-size: $font-size;

    overflow: hidden;
    white-space: nowrap;
}

.sidebar_light_theme {
    background-color: $background-light;
    color: $text-color-light;
    transition: background-color 0.4s;
}
.sidebar_dark_theme {
    background-color: $background-dark;
    color: $text-color-dark;
    transition: background-color 0.4s;
}

.sidebar_menu {
    margin-left: 10px;
    height: calc(100vh - 140px);

    display: grid;
    gap: 0px;
    grid-template-columns: 40px 10px 1fr 10px 20px;
    grid-auto-rows: min-content;
    
    overflow-y: auto;
    overflow-x: hidden;
}
.sidebar_menu_show{
    width: var(--sidebarwidth);
    transition: all 0.4s;
}

.sidebar_menu_hide{
    width: 40px;
    transition: all 0.4s;
}

.sidebar_item_group {
    display: contents;
}
.sidebar_row {
    display: contents;
}

.sidebar_row > span {
    // box-shadow: 2px 2px 10px rgb(0, 0, 0, 0.25); 
    overflow: hidden;
}
.sidebar_bleft {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar_bright{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar_b{
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar_row:hover span{
    background-color: $primary;
    color: $primary-white;
    transition: all ease 0.3s;
}
.sidebar_row_selected span{
    background-color: $primary;
    color: $primary-white;
}
.sidebar_hr { 
    grid-column: 1 / 6;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}
.sidebar_hr > hr {
    flex: 1;  
    border-top: none;
}
.sidebar_hr_light > hr{
    border: 1px solid $border-color-gray-light;
    border-top: none;
}
.sidebar_hr_dark > hr{
    border: 1px solid $border-color-gray-dark;
    border-top: none;
}

.sidebar_item {
    grid-column: 2 / 4;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0px;
    height: 40px;
}
.sidebar_item p {
    padding: 10px;
}
.sidebar_subitem {
    white-space: nowrap;   
}
.sidebar_subitem p {
    padding: 4px;
}

.sidebar_subitem_show {
    height: 30px;
    opacity: 1;
    transition: height 0.3s, opacity 0.35s;
}
.sidebar_subitem_hide {
    padding: 0px;
    height: 0px;
    opacity: 0;
    transition: height 0.4s, opacity 0.125s;
}

.sidebar_action {
    width: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.sidebar_button_light_theme{
    width: 100%;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    &:hover{
        cursor: pointer;
        background-color: $primary;
        color: $primary-white;
        transition: all .4s ease;
        border-radius: 2px;
    }
}
.sidebar_button_dark_theme{
    width: 100%;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    &:hover{
        cursor: pointer;
        background-color: $primary;
        transition: background-color .4s ease;
        border-radius: 2px;
    }
}
.sidebar_icon {
    position: relative;
    top: 42px;
    left: -5px;
}

// .sidebar_arrow_down{
//     transition: opacity 1s ease-in-out;
// }
// .sidebar_arrow_right{
//     transition: opacity 1s ease-in-out;
// }

// &:after {
//     content: "";
//     width: 10px;
//     height: 100px;
//     top: -16px;
//     position: relative;

// }

// &:hover:after {
//     cursor: pointer;
//     background-color: red;
// }

