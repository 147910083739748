

.contacto_container_format {
    margin: auto;
    width: 95%;
    padding-bottom: 80px;
    max-width: 1300px;
    justify-content: center;
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "titulo titulo  "
                         "texto  contacto";
}
.contacto_title_container {
    padding: 50px 0px 10px 0px;
    font-size: 48px;
    grid-area: titulo;
}
.contact_container_info{
    grid-area: texto;
}
.contacto_container_form {
    grid-area: contacto;
}

@media (max-width: 1480px) {
    .contacto_title_container {
        width: unset;
    }
}

@media (max-width: 900px) {
    .contacto_container_format {
        grid-template-columns: 1fr;
        grid-template-areas: "titulo  "
                             "texto   "
                             "contacto";
        gap: 0px;
        padding-bottom: 20px;
    }
    .contacto_title_container {
        font-size: 40px;
        padding: 30px 30px 0px 30px;
    }
    .contact_container_info{
        padding: 20px 30px 0px 30px;
    }
    .contacto_container_form {
        padding: 30px;
    }
}