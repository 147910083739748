@import '../../../../../../../styles/base/settings';

.plan_main {
    height: 650px;
    display: flex;
    align-items: center;
}
.plan_section {
    width: 100%;
    //padding: 30px 20px 150px 20px;
    //padding: 80px;
    border-radius: 5px;
    -moz-border-radius: 15px;
    //border: 1px solid #0000001f;
    align-items: center;
}
.plan_format {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    margin: 0 auto;
    max-width: 850px;
    padding: 45px;
    border-radius: 5px;
    -moz-border-radius: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "icono    titulo"
                         "icono    dato1 "
                         "icono    dato2 "
                         "icono    opcion";
}
.plan_icono {
    grid-area: icono;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}
.plan_titulo {
    grid-area: titulo;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-titulo-principal;
}
.plan_dato1 {
    grid-area: dato1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-descripcion-precio;
}
.plan_dato1_color {
    color: $color-principal;
}
.plan_dato2 {
    grid-area: dato2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
/*.plan_dato3 {
    grid-area: dato3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-texto-mediano;
}*/
.plan_opcion {
    grid-area: opcion;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1000px) {
    .plan_section {
        width: 100%;
        padding: 20px;
        border-radius: 5px;
        -moz-border-radius: 15px;
    }
    .plan_icono {
        height: 200px;
        width: unset;
    }
    .plan_format {
        padding: 20px;
        gap: 20px;
        grid-template-columns: 1fr;
        grid-template-areas: "icono "    
                             "titulo"
                             "dato1 "
                             "dato2 "
                             "opcion";
    }
}

@media (max-width: 700px) {
    .plan_main {
        margin: -15px -20px 0px -35px
    }
    .plan_titulo {
        font-size: 28px;
    }
    .plan_dato1 {
        font-size: 18px;
    }
}

@media (max-width: 400px) {
    .plan_main {
        margin: -15px -20px 0px -35px
    }
    .plan_titulo {
        font-size: 28px;
    }
    .plan_dato1 {
        font-size: 18px;
    }
    .plan_icono {
        height: 150px;
    }
    .plan_format {
        padding: 10px;
    }
    .plan_section {
        padding: 12px;
    }
}