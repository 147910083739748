* {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin: 0px;
}

.light_theme {
    background-color: $primary-white;
    color: $text-color-light;
}

.dark_theme {
    background-color: $background-dark;
    color: $text-color-dark;
}

.clear {
    clear: both;
    float: left;
    width: 10px;
}