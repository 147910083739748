@import '../../../../../styles/base/settings';

.myquestions_wait {
    height: 666px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.question_modal_web {  /* MODAL DE PREGUNTAS */
    max-width: 500px;
    height: 550px;
    border-radius: 5px;
    -moz-border-radius: 15px;
    font-size: 14px;

    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image     image"
                         "titulo    titulo"
                         "dato1     dato1"
                         "dato2     dato2"
                         "opcion1   opcion2"
                         "cerrar    cerrar";
}
.question_modal_image{
    grid-area: image;
}
.question_modal_titulo {
    grid-area: titulo;
    text-align: center;
    font-size: 30px;
}
.question_modal_dato1 {
    grid-area: dato1;
    text-align: center;
    font-size: 15px;
}
.question_modal_dato2 {
    grid-area: dato2;
    text-align: center;
    font-size: 15px;
}
.question_modal_opcion1 {
    grid-area: opcion1;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.question_modal_opcion2 {
    grid-area: opcion2;
    display: flex;
    justify-content: center;
    align-items: center;
}  
.question_modal_cerrar {
    grid-area: cerrar;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}                       /* MODAL DE PREGUNTAS */



@media (max-width: 700px) {////////   RESPONSIVE ( MEDIA QUERY ) HOME PRINCIPAL   ////////
    .question_modal_web {
        padding: 0px;
    }
}

@media (max-width: 400px) {////////   RESPONSIVE ( MEDIA QUERY ) HOME PRINCIPAL   ////////
    .question_modal_titulo {
        font-size: 25px;
    }
}