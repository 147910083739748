@import '../../../../../../../styles/base/settings';

.respuestas_title {
    max-width: 1000px;
    margin: 0 auto;
    font-size: $font-titulo-principal
}

.respuestas_format {
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 10px 10px 15px;
    border-radius: 5px;
    display: grid;
    gap: 10px;
    grid-template-columns: 200px 1fr 1fr 160px;
    grid-template-areas: "imagen     titulo     precio     opciones"
                         "pregunta   pregunta   pregunta   estado  "
                         "opcion     opcion     opcion     opcion";
}

.respuestas_imagen {
    grid-area: imagen;
}
.respuestas_titulo {
    grid-area: titulo;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-descripcion-precio;
}
.respuestas_precio {
    grid-area: precio;
    display: flex;
    align-items: center;
    font-size: $font-descripcion-precio;
    color: $color-azul;
}
.respuestas_opciones {
    grid-area: opciones;
    display: flex;
    align-items: center;
}
.respuestas_pregunta {
    grid-area: pregunta;
    font-size: $font-texto-mediano;
}
.respuestas_estado {
    grid-area: estado;
}
.respuestas_opcion {
    grid-area: opcion;
    display: flex;
}

@media (max-width: 685px) {
    .respuestas_main{
        margin: -10px -10px 10px -10px;
    }
    .respuestas_title {
        font-size: 28px;
    }
    .respuestas_format {
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 15px 10px 15px 15px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "imagen     titulo  "
                             "precio     precio  "
                             "pregunta   pregunta"
                             "estado     estado  "
                             "opcion     opcion  "
                             "opciones   opciones";
    }
    .respuestas_opciones {
        justify-content: flex-end;
    }
}