@import '../../../styles/base/settings';

.login_principal {
    margin: 0px;
    padding: 0px;
    background-color: $primary-white;
    height: 700px;
    display: flex;
    justify-content: center;
}
.login_grid {
    width: 340px;
    box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.25);
    // border: 1px solid #000000;
    margin-top: 150px;
}
.login_header {
    background-color: black;
    color: $primary-white;
    display: flex;
    padding: 15px;
    h2 {
        margin-top: 6px;
    }
}
.login_format {
    display: grid;
    grid-template-columns: 1fr;
    padding: 28px;
}
.login_user {
    display: flex;
    margin-bottom: 25px;
}
.login_password {
    display: flex;
    margin-bottom: 25px;
}
.login_text {
    color: $alert-green;
}
.login_textalert {
    color: $alert-red;
}
.login_button {
    margin-bottom: 8px;
}
.login_info {
    
}   
.login_info > p {
    text-align: center;
}

.login_register {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
}

@media (max-width: 300px) {
    .login_principal {
        height: 500px;
    }
    .login_grid {
        width: 270px;
        margin-top: 55px;
    }
}