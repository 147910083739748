@import '../../../../../../../styles/base/settings';

.preguntas_titulo {
    max-width: 900px;
    margin: 0 auto;
    font-size: $font-titulo-principal
}

.preguntas_format {
    max-width: 900px;
    margin: 0 auto;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 20px 10px 15px;
    border-radius: 5px;
    display: grid;
    gap: 8px;
    grid-template-columns: 200px 1fr 180px;
    grid-template-areas: "imagen   publicacion   boton    "
                         "imagen   pregunta      pregunta "
                         "imagen   respuesta     respuesta"
                         "imagen   opciones      opciones";
}
.preguntas_format_imagen {
    grid-area: imagen;
}
.preguntas_imagen_height {
    height: 150px;
}
.preguntas_format_publicacion {
    grid-area: publicacion;
    font-size: $font-descripcion-precio;
    font-Weight: 500;
    color: rgb(0, 93, 199);
    width: 100%;
}
.preguntas_format_boton {
    grid-area: boton;
    text-align: end;
}
.preguntas_format_pregunta {
    grid-area: pregunta;
}
.preguntas_format_respuesta {
    grid-area: respuesta;
    display: flex;
    align-items: center;
}
.preguntas_format_opciones {
    grid-area: opciones;
    text-align: end;
}

.estado_pregunta_color_verde{
    color: rgb(18, 161, 18);
    margin-left: 10px;
}
.estado_pregunta_color_rojo{
    color: red;
    margin-left: 10px;
}
.estado_pregunta_color_amarillo{
    color: rgb(236, 154, 0);
}

@media (max-width: 685px) {
    .preguntas_main{
        margin: -20px -5px 10px -10px;
    }
    .preguntas_titulo {
        font-size: 28px;
    }
    .preguntas_format {
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 10px 10px 5px 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "imagen        imagen   "
                             "publicacion   boton    "
                             "pregunta      pregunta "
                             "respuesta     respuesta"
                             "opciones      opciones ";
    }
}

@media (max-width: 450px) {
    .preguntas_format {
        grid-template-columns: 1fr;
        grid-template-areas: "imagen     "
                             "publicacion"   
                             "boton      "
                             "pregunta   "
                             "respuesta  "
                             "opciones   ";
    }
    .preguntas_format_publicacion {
        text-align: center;
    }
    .preguntas_format_boton {
        text-align: center;
    }
    .preguntas_format_pregunta {
        text-align: center;
    }
    .preguntas_format_respuesta {
        display: block;
        text-align: center;
    }
    .preguntas_format_opciones {
        text-align: center;
    }
}

@media (max-width: 300px) {
    .preguntas_main{
        margin: -20px -10px 10px -10px;
    }
    .preguntas_format_publicacion {
        font-size: 20px;
    }
    .preguntas_format_pregunta {
        font-size: 14px;
    }
    .preguntas_format_respuesta {
        font-size: 14px;
    }
}