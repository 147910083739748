
.carousel-container {
    //margin: 20px;
}
  
.selected-image {
    width: 100%;
    height: 500px;
    margin-bottom: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
  
.carousel {
    position: relative;
}
  
.carousel__images {
    display: flex;
    max-width: 100%;
    overflow-x: hidden;
}
  
.carousel__image-selected {
    border: 3px solid #ffa700 !important;
}
  
.carousel__image {
    margin-right: 10px;
    height: 100px;
    min-width: 100px;
    border: 3px solid #ffa70000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
  
.carousel__button {
    top: 40%;
}
  
.carousel__button-left {
    left: 10px;
}
  
.carousel__button-right {
    right: 10px;
}

.carousel_image_style {
    max-height: 90vh;
    min-height: 90vh;
}

@media (max-width: 1200px) {
    .carousel_image_style {
        max-height: 60vh;
        min-height: 60vh;
    }
}

@media (max-width: 800px) {
    .carousel_image_style {
        max-height: 90vh;
        min-height: unset;
    }
}