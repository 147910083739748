@import '../../../styles/base/settings';

// HOJA DE ESTILOS COMPONENTE "HOME" 

////////////////////////////    ESTILO PRINCIPAL DEL HOME    ////////////////////////////
.home_principal {
    display: grid;
    gap: 20px;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "slider      slider      slider      slider    "
                         "instalar    instalar    instalar    instalar  "
                         "carousel    carousel    carousel    carousel  "
                         "asociados   asociados   asociados   asociados "
                         "videos      videos      videos      videos    ";
}
@media (max-width: 600px) {////////   RESPONSIVE ( MEDIA QUERY ) HOME PRINCIPAL   ////////
    .home_principal {
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "slider      slider    "
                             "instalar    instalar  "
                             "carousel    carousel  "
                             "asociados   asociados "
                             "videos      videos    ";
    }
    .carousel, .home_products_1, .home_products_2, .home_news_1, .home_news_2{
        //width: 90%;
        margin-left: 5%;
    }
} //////////////////////////    ESTILO PRINCIPAL DEL HOME    ////////////////////////////

.home_subtitulos{  //////  ESTILO PARA LOS SUBTITULOS DE LOS COMPONENTES DEL HOME  //////
    font-size: $font-titulo-principal;
    margin: 0px 0px 30px 0px;
}

.home_slide_buttons_orange{  ///////////   MARGEN PARA LOS BOTONES NARANJAS   ///////////
    margin-bottom: 30px;
}

.home_instalar_app {
    grid-area: instalar;
}

/*********************     ESTILOS PARA EL SLIDER PRINCIPAL DEL "HOME"      *********************/
.home_slider {/////////////////    CONTENEDOR PARA ALINEAR EL SLIDER    /////////////////
    grid-area: slider;
    justify-content: center;
    background-color: $color-secundario;
    height: 280px;
    
}
.home_slider_container{ /////////////    CONTENEDOR PRINCIPAL DEL SLIDER    /////////////
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    //width: 90%;
    max-width: 1366px;
    margin: auto;
}
.home_slider_container_text{  ////////////    CONTENEDOR PARA LOS TEXTOS DEL SLIDER    ////////////
    background-color: $color-secundario;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home_slide_text { /////////  PADDING PARA LOS DIV'S DE TEXTOS DEL SLIDER  /////////
    padding: 30px 50px 30px 50px;
    div > div {
        font-size: 26px;
        font-weight: 500;
    }
    p {
        font-size: 19px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
.home_slider_image{   //////////////  ESTILO PARA LAS IMAGENES DEL SLIDER  //////////////
    overflow: hidden;
    height: 280px;
}


/**********************     ESTILOS PARA EL CARRUSEL DE PUBLICACIONES      **********************/
.home_carousel_publicaciones {   ////  CONTENEDOR PARA EL CARRUSEL DE PUBLICACIONES  ////
    grid-area: carousel;
    padding-bottom: 30px;
    width: 90%;
    max-width: 1366px;
    margin: auto;
}
/*.home_carousel_cards{  ///////  ESPACIADO PARA LAS TARJETAS DE LAS PUBLICACIONES  ///////
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    height: 100%;
    margin: 2px 10px 2px 10px;
    height: 390px;
}
.home_carousel_cards:hover {
    box-shadow: 1px 1px 6px 2px rgb(41, 37, 37);
    cursor: pointer;
}*/
.home_carousel_publications {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    margin: 2px 10px 2px 10px;
    display: grid;
    gap: 15px;
    grid-template-columns: 60px 1fr;
    grid-template-areas: "imagen        imagen     "
                         "icono         titulo     "
                         "descripcion   descripcion";
    padding-bottom: 100%;
    margin-bottom: -100%;
}
.home_carousel_publications:hover {
    box-shadow: 1px 1px 6px 2px rgb(41, 37, 37);
    cursor: pointer;
}
.home_carousel_publication_imagen {
    grid-area: imagen;
}
.home_carousel_publication_icon {
    grid-area: icono;
    display: flex;
    justify-content: center;
}
.home_carousel_publication_title {
    grid-area: titulo;
    text-align: start;
}
.home_carousel_publication_description {
    grid-area: descripcion;
    margin-bottom: 15px;
    text-align: center;
}

/*********************     ESTILOS PARA EL CARRUSEL DE PUBLICACIONES      **********************/

.home_asociados {  ///////////    CONTENEDOR PARA EL CARRUSEL DE ASOCIADOS    ///////////
    grid-area: asociados;
    color: white;
    background-color: $color-secundario;
    padding: 30px 0px 30px 0px;
}
.home_asociados_container {
    width: 90%;
    max-width: 1366px;
    margin: auto;
}
.home_asociados_fotos_container {
    position: relative;
    margin: 2px 10px 2px 10px;
}
.home_asociados_fotos_logo {
    position: absolute;
    top: 1%;
    left: 18%;
    transform: translate(-40%, 10%);
}
.home_asociados_fotos_texto {
    position: absolute;
    bottom: 8%;
    right: 5%;
    background-color: #004e98;
    border-radius: 8px;
    max-width: 250px;
}

.home_videos {  ////////////    CONTENEDOR PARA EL CARRUSEL DE LOS VIDEOS    ////////////
    grid-area: videos;
    padding: 25px 0px 70px 0px;
    width: 90%;
    max-width: 1366px;
    margin: auto;
}

.buscar_modal_content {
    display: flex;
    justify-content: center;
    padding: 30px 30px 50px 30px;
}

@media (max-width: 1000px) {//////   RESPONSIVE ( MEDIA QUERY ) SLIDER PRINCIPAL  ///////
    .home_principal {
        gap: 5px;
    }
    .home_slider {
        height: auto;
        background-color: transparent;
    }
    .home_slider_container{
        grid-template-columns: 1fr; // EL CONTENEDOR SLIDER PASA DE DOS COLUM. A UNA SOLA
    }
    .home_slide_text { /////////  PADDING PARA LOS DIV'S DE TEXTOS DEL SLIDER  /////////
        padding: 20px;
        height: 120px;
        display: flex;
        align-items: center;
        div > div {
            font-size: 24px;
            padding: 0px;
        }
        p {
            font-size: 12px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .home_asociados {  ///////////    CONTENEDOR PARA EL CARRUSEL DE ASOCIADOS    ///////////
        padding: 30px 0px 10px 0px;
    }
    .home_asociados_fotos_texto {
        position: absolute;
        bottom: 8%;
        right: 5%;
        background-color: #004e98;
        border-radius: 8px;
        max-width: 200px;
    }
    .home_slider_image {
        height: 180px;  // CAMBIA EL TAMAÑO DE LAS IMAGENES DEL SLIDER
    }
    .home_carousel_publicaciones {   ////  CONTENEDOR PARA EL CARRUSEL DE PUBLICACIONES  ////
        width: 100%;
        padding: 0px 0px 15px 0px;
    }
    .home_asociados_container {
        width: 100%;
    }
    .home_subtitulos{
        font-size: $font-subtitulo-grande;
    }
}/*********************     ESTILOS PARA EL SLIDER PRINCIPAL DEL "HOME"     *********************/

@media (max-width: 340px) {
    .home_carousel_cards {
        height: 430px;
    }
    .home_videos {
        width: 100%;
    }
    .home_subtitulos{
        font-size: 22px;
    }
}
@media (max-width: 300px) {
    .home_carousel_publications {
        gap: 15px;
        grid-template-columns: 1fr;
        grid-template-areas: "imagen     "
                             "icono      "
                             "titulo     "
                             "descripcion";
        padding-bottom: 100%;
        margin-bottom: -100%;
    }
    .home_carousel_cards {
        height: 430px;
    }
    .home_slide_text {
        div > div {
            font-size: 20px;
            padding: 0px;
        }
    }
    .buscar_modal_content {
        padding: 30px;
    }
    .home_asociados_fotos_logo {
        top: 2%;
        left: 25%;
    }
    .home_asociados_fotos_texto {
        bottom: 8%;
        right: 5%;
        border-radius: 8px;
        max-width: 140px;
    }
    .home_carousel_publication_description {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .home_videos {
        padding: 25px 0px 30px 0px;
    }
}