@import '../../../../../../../styles/base/settings';

.tarjetas_titulo {
    max-width: 800px;
    margin: 0 auto;
    font-size: $font-titulo-principal
}

.tarjetas_format {
    margin: 0 auto;
    max-width: 800px;
    padding: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    -moz-border-radius: 15px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    font-size: 14px;
    background-color: white;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 100px;
    grid-template-areas: "tarjeta   opcion1"
                         "tarjeta   opcion2";
}
.tarjetas_tarjeta {
    grid-area: tarjeta;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tarjetas_opcion1 {
    grid-area: opcion1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.tarjetas_opcion2 {
    grid-area: opcion2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.tarjetas_tarjeta_container {
    position: relative;
}
.tarjetas_tarjeta_imagen img{
    height: 260px;
}
.tarjetas_tarjeta_datos {
    position: absolute;
    top: 75px;
    padding: 20px;
    color: white;
    width: 100%;
}
.tarjetas_tarjeta_datos_numero {
    font-size: $font-subtitulo-grande;
    margin-bottom: 10px;
}
.tarjetas_tarjeta_datos_nombre {
    font-size: $font-descripcion-precio;
    margin-bottom: 30px;
}
.tarjetas_tarjeta_datos_fechaCVV {
    font-size: $font-texto-grande;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.tarjetas_tarjeta_datos_CVV {
    text-align: end;
}

.tarjetas_modal_title{
    padding: 30px 30px 0px 30px;
}

@media (max-width: 600px) {
    .tarjetas_main {
        margin: -10px -15px 0px -20px
    }
    .tarjetas_titulo {
        font-size: 28px;
    }
    .tarjetas_format{
        padding: 10px;
        gap: 5px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "tarjeta   tarjeta"
                             "opcion1   opcion2";
    }
    .tarjetas_opcion1 {
        display: flex;
        justify-content: flex-end;
    }
    .tarjetas_opcion2 {
        display: flex;
        justify-content: flex-start;
    }
    .tarjetas_tarjeta_imagen img{
        height: 160px;
    }
    .tarjetas_tarjeta_datos {
        top: 45px;
        padding: 12px;
    }
    .tarjetas_tarjeta_datos_numero {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .tarjetas_tarjeta_datos_nombre {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .tarjetas_tarjeta_datos_fechaCVV {
        font-size: 12px;
    }
    .tarjetas_tarjeta_datos_CVV {
        text-align: center;
    }
}

@media (max-width: 400px) {
    .tarjetas_section{
        padding: 0px 10px 0px 10px;
    }
    .tarjetas_format{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .tarjetas_tarjeta_imagen img{
        height: 140px;
    }
    .tarjetas_tarjeta_datos {
        top: 30px;
    }
    .tarjetas_tarjeta_datos_numero {
        font-size: 17px;
        margin-bottom: 5px;
    }
}