@import '../../../styles/base/settings';

.prinuser_principal {
    display: grid;
    gap: 20px;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "slider           slider           slider           slider       "
                         "instalar         instalar         instalar         instalar     "
                         "carousel         carousel         carousel         carousel     "
                         "publicaciones    publicaciones    publicaciones    publicaciones"
                         "asociados        asociados        asociados        asociados    "
                         "videos           videos           videos           videos       ";
}

.prinuser_carousel {
    grid-area: slider;
    background-color: $color-secundario;
    height: 280px;
    text-align: center;
    justify-content: center;
}
.prinuser_carousel_container {
    margin: 0 auto;
    max-width: 1366px;
    display: grid;
    position: relative;
    width: 100%;
    grid-template-columns: 2fr 2fr;
}
.prinuser_carousel_image {
    overflow: hidden;
    height: 280px;
}

.prinuser_carousel_text {
    background-color: $color-principal;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.prinuser_carousel_title {
    padding: 30px 50px 30px 50px;
    div > div {
        font-size: 30px;
        font-weight: 500;
    }
    p {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 19px;
    }
}

.triangulo-equilatero-bottom-left {
    position: absolute;
    right: 50%;
    bottom: 0;
    border-right: 90px solid $color-principal;
    border-top: 280px solid transparent;
    border-bottom: 0px solid transparent; 
}

.prinuser_titulos_principales {
    font-size: $font-titulo-principal;
    text-align: center;
    padding-bottom: 30px;
}

.prinuser_instalar_app {
    grid-area: instalar;
}

.prinuser_slider_container {
    grid-area: carousel;
    width: 90%;
    margin: auto;
    max-width: 1366px;
    padding-bottom: 50px;
}
.prinuser_carousel_cards {
    padding: 20px;
    margin: 2px 10px 2px 10px;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    height: 300px;
    //background-color: aqua;
}
.prinuser_carousel_cards:hover{
    box-shadow: 1px 1px 6px 2px rgb(41, 37, 37);
    cursor: pointer;
}
.prinuser_carousel_card_title {
    font-size: $font-descripcion-precio;
    font-weight: 500;
    margin-bottom: 10px;
}
.prinuser_carousel_card_descripcion {
    font-size: $font-texto-grande;
    margin-top: 10px;
    div {
        color: $color-azul;
        font-size: $font-descripcion-precio;
        font-weight: 500;
        margin-bottom: 10px;
    }
    p {
        color: $color-gris;
        font-size: $font-texto-chico;
        margin-top: 10px;
    }
}

.prinuser_publicaciones {
    grid-area: publicaciones;
    width: 90%;
    margin: auto;
    max-width: 1366px;
    padding-bottom: 50px;
}

.prinuser_ofertas_container {
    grid-area: asociados;
    color: white;
    background-color: $color-secundario;
    padding-top: 30px;
    padding-bottom: 60px;
}
.prinuser_ofertas_format {
    max-width: 1366px;
    margin: 0 auto;
    width: 90%;
}
.prinuser_ofertas_container_images {
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
}
.prinuser_ofertas_container_images:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.prinuser_buscar_modal_content {
    display: flex;
    justify-content: center;
    padding: 30px;
}

.prinuser_videos{
    grid-area: videos;
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .prinuser_principal {
        gap: 5px;
    }
    .prinuser_carousel {
        height: auto;
        background-color: transparent;
    }
    .prinuser_carousel_container{
        grid-template-columns: 1fr;
    }
    .prinuser_carousel_image {
        height: 180px;
    }
    .prinuser_carousel_text{
        padding: 20px;
    }
    .prinuser_carousel_title {
        padding: 0px;
        height: 120px;
        display: flex;
        align-items: center;
        div > div {
            font-size: $font-subtitulo-chico;
            padding: 0px;
        }
        p {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: $font-texto-mediano;
        }
    }
    .prinuser_slider_container {
        padding-bottom: 30px;
        padding-top: 0px;
    }
    .prinuser_titulos_principales {
        margin-left: 10px;
        font-size: $font-subtitulo-grande;
    }
    .prinuser_carousel_card_title {
        font-size: $font-texto-grande;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .prinuser_carousel_cards {
        height: 280px;
        padding: 15px;
    }

    .triangulo-equilatero-bottom-left {
        border-right: 80px solid transparent;
    }
}
@media (max-width: 600px) {
    .prinuser_slider_container {
        width: 100%;
    }
    .prinuser_publicaciones {
        width: 100%;
    }
    .prinuser_ofertas_container{
        width: 100%;
    }
    .prinuser_ofertas_format {
        width: 100%;
    }
    .prinuser_carousel{
        width: 100%;
    }
}

@media (max-width: 300px) {
    .prinuser_carousel_title {
        div > div {
            font-size: 20px;
            padding: 0px;
        }
        p {
            font-size: 13px;
        }
    }
    .prinuser_titulos_principales {
        font-size: 22px;
    }
}