@import '../../../../../../../styles/base/settings';

.dir_main{
    //margin-bottom: 200px;
}

.dir_titulo {
    max-width: 800px;
    margin: 0 auto;
    font-size: $font-titulo-principal
}

.dir_format {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 6px;
    display: grid;
    gap: 20px;
    grid-template-columns: 120px 1fr 100px;
    grid-template-areas: "icono   datos   opcion";
}
.dir_icono {
    grid-area: icono;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dir_datos {
    grid-area: datos;
    font-size: $font-texto-chico;
    p {
        font-size: $font-texto-grande;
        font-weight: 500;
    }
}
.dir_opcion {
    grid-area: opcion;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dir_modal_title {
    padding: 30px 30px 0px 30px;
}
.dir_modal_format {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "nombre        nombre        nombre      "
                         "cp            estado        municipio   "
                         "colonia       calle         numExt      "
                         "espacio       numInt        numInt      "
                         "subcontenido  subcontenido  subcontenido";
}
.dir_modal_nombre {
    grid-area: nombre;
}
.dir_modal_cp {
    grid-area: cp;
}
.dir_modal_estado {
    grid-area: estado;
}
.dir_modal_municipio {
    grid-area: municipio;
}
.dir_modal_colonia {
    grid-area: colonia;
}
.dir_modal_numExt {
    grid-area: numExt;
}
.dir_modal_numInt {
    grid-area: numInt;
}
.dir_modal_subcontenido {
    grid-area: subcontenido;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title        title      "
                         "calle1       calle2     "
                         "telefono1    telefono2  "
                         "descripcion  descripcion";
}
.dir_modal_subcontenido_title {
    grid-area: title;
}
.dir_modal_subcontenido_calle1 {
    grid-area: calle1;
}
.dir_modal_subcontenido_calle2 {
    grid-area: calle2;
}
.dir_modal_subcontenido_telefono1 {
    grid-area: telefono1;
}
.dir_modal_subcontenido_telefono2 {
    grid-area: telefono2;
}
.dir_modal_subcontenido_descripcion {
    grid-area: descripcion;
}

@media ( max-width: 500px ) {
    .dir_main{
        margin-bottom: 0px;
        margin: -10px -11px -10px -20px;
    }
    .dir_titulo {
        font-size: 28px;
    }
    .dir_format {
        margin-top: 10px;
        margin-bottom: 20px;
        grid-template-columns: 1fr 80px;
        grid-template-areas: "icono   icono"
                             "datos   opcion";
    }
   
    .dir_opcion {
        align-items: flex-end;
    }
    .dir_modal_format {
        grid-template-columns: 1fr;
        grid-template-areas: "nombre      "
                             "cp          "
                             "estado      "
                             "municipio   "
                             "colonia     "
                             "calle       "
                             "numExt      "
                             "numInt      "
                             "subcontenido";
    }
    .dir_modal_subcontenido {
        grid-template-columns: 1fr;
        grid-template-areas: "title      "
                             "calle1     "
                             "calle2     "
                             "telefono1  "
                             "telefono2  "
                             "descripcion";
    }
}