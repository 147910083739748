
.public_route_container_cargando {
    display: flex;
    justify-content: center;
    align-items: center;
}
.public_route_cargando{
    display: block;
    align-self: auto;
    margin: 450px 0px 450px 0px;
}
@media (max-width: 850px) {
    .public_route_cargando{
        margin: 300px 0px 300px 0px;
    }
}