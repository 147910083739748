@import '../../../../../../../styles/base/settings';

.ventas_icon_soon {
    width: 100%;
    fill: $color-azul;
}

.ventas_publications_container {
    //padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;
}

.ventas_proximo_container {
    display: grid;
    gap: 20px;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    div {
        font-size: 32px;
        font-weight: 500;
        justify-content: center;
    }
    p {
        font-size: 44px;
        font-weight: 600;
        color: $color-principal;
    }
}

@media (max-width: 960px) {
    .ventas_proximo_container {
        grid-template-columns: 1fr;
        text-align: center;
        gap: 0px;
    }
}

@media (max-width: 600px) {
    .ventas_publications_container {
        height: 500px;
    }
}

@media (max-width: 400px) {
    .ventas_proximo_container {
        div {
            font-size: 26px;
        }
        p {
            font-size: 34px;
        }
    }
}
@media (max-width: 300px) {
    .ventas_proximo_container {
        div {
            font-size: 22px;
        }
        p {
            font-size: 26px;
        }
    }
}