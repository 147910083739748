@import '../../../../../../../styles/base/settings';

.sales_publications_container {
    padding: 50px;
}
.sales_titulo_principal {
    font-size: $font-titulo-principal;
    font-size: $font-titulo-principal;
    padding-bottom: 15px;
}
.sales_container_cards {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1350px;
    margin: 0 auto;
}
.sales_format_cards {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    height: 500px;
}
.sales_format_cards:hover {
    box-shadow: 1px 1px 6px 2px rgb(41, 37, 37);
    cursor: pointer;
}

.sales_cards_imagen {
    margin: 0 auto;
    height: 250px;
}
.sales_cards_style_image {
    height: 250px;
}

.sales_format_cards_datos {
    padding: 10px 20px 20px 20px;
    font-size: $font-texto-grande;
    display: grid;
    gap: 10px;
    height: 40%;
}
.sales_format_card_subdatos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
    font-size: $font-texto-chico;
}
.sales_datos_nombre_producto {
    font-size: $font-descripcion-precio;
    font-weight: 500;
}
.sales_datos_titulo {
    color: rgb(0, 93, 199);
    font-size: 20px;
    font-weight: 500;
}
.sales_button {
    text-align: center;
}
.sales_format {
    padding: 20px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    -moz-border-radius: 15px;
}

.sales_section_no_publicar {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center; 
    align-items: center;
}
.sales_section_no_publicar_container {
    display: flex;
    max-width: 600px;
}
.sales_section_no_publicar_icon {
    margin-right: 20px;
}

@media (max-width: 1400px) {
    .sales_container_cards {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1100px) {
    .sales_container_cards {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 850px) {
    .sales_datos_nombre_producto {
        font-size: $font-texto-grande;
    }
    .sales_format_cards_datos {
        padding: 10px 15px 15px 15px;
    }
    .sales_datos_precio {
        margin-bottom: 10px;
        text-align: center;
        font-size: $font-descripcion-precio;
    }
    .sales_titulo_principal {
        font-size: $font-subtitulo-chico;
    }
}

@media (max-width: 600px) {
    .sales_publications_container {
        padding: 20px;
    }
    .sales_container_cards {
        grid-template-columns: 1fr;
    }
    .sales_format_cards {
        height: unset;
    }
    .sales_format_cards_datos {
        height: unset;
    }
    .sales_cards_imagen {
        height: 200px;
    }
    .sales_cards_style_image {
        height: 200px;
    }
    
    .sales_datos_estado {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
        padding: 0px;
    }
    .sales_section_no_publicar_container {
        display: unset;
    }
    .sales_section_no_publicar_icon {
        margin-right: unset;
    }
}