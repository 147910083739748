@import '../../../../../styles/base/settings';

.catalogo_principal {
    height: calc(100vh - 120px);
    width: calc(100vw  - var(--sidebarwidth));
    min-width: calc(1024px  - var(--sidebarwidth));
    min-height: 700px;
    display: flex;
    transition: all 0.4s;
}

.catalogo_light_theme {
   @include theme-text-colors($dark-theme: false);
}
 .catalogo_dark_theme {
    @include theme-text-colors($dark-theme: true);
 }

.catalogo_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 50px 50px 1fr 130px;
    grid-template-areas: "title       title       title        title     "
                         "search      search      search       search    "
                         "list        list        list         list      "
                         "controllers controllers controllers  controllers";
    font-size: $font-size;
    overflow-y: scroll;
}

.catalogo_grid_dark_theme {
    @include theme-component-colors($dark-theme: true);
}

.catalogo_grid_light_theme {
    @include theme-component-colors($dark-theme: false);
}

.catalogo_grid_dark_theme > div {
    border-color: $border-color-gray-dark;
}

.catalogo_grid_light_theme > div {
    border-color: $border-color-gray-light;
}

.catalogo_title {
    grid-area: title;
    font-size: $font-big;
    display: flex;
    padding: 12px;

    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}
.catalogo_logo {
    color: $primary;
    margin-right: 10px;
}
.catalogo_text {
    padding-top: 2px;
} 

.catalogo_search {
    grid-area: search;
    font-size: $font-size;
    padding: 10px;
    overflow-x: hidden;

    //Eevitar seleccionar elementos
    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
.catalogo_right {
    padding-right: 10px;
    padding-top: 3px;
    vertical-align: middle;
    float: right;
}
.catalogo_left {
    align-self: center;
    float: left;
}
.catalogo_sort {
    padding-top: 10px; 
}
.catalogo_color_green {
    color: $alert-green;
}
.catalogo_color_red {
    color: $alert-red;
}

.catalogo_list {
    grid-area: list; 
    margin-left: 10px;
    margin-right: 10px;

    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;

    overflow-x: hidden;
}
.catalogo_list:hover {
    overflow-x: auto;
}
.catalogo_list_icon {
    color: $alert-blue;
}
.catalogo_list_picture {
    color: $alert-red;
}

.catalogo_table {
    // width: calc(100% - 2px);
    // width: 0px;
    // width: auto;
    table-layout: fixed;
    display: table; 
    border-collapse: separate;
    border-style: solid;
    border-width: 0px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 1px;
    border-spacing: 0px;

    border-radius: 5px;
}
.catalogo_table_dark{
    @include theme-component-colors($dark-theme: true);
}
.catalogo_table_light{
    @include theme-component-colors($dark-theme: false);
}


.catalogo_header {
    //Eevitar seleccionar elementos
    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 

    border-collapse: collapse;
    border-style: none;
    border-color: inherit;

    display: table-header-group;
}
.catalogo_header :last-child{
    //regla para evitar duplicar linea en el titulo final del encabezado
    border-right-width: 0px;
}
.catalogo_header_item {
    height: 40px;
    
    border-color: inherit;
    border-collapse: collapse;
    border-style: solid;
    border-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 1px;

    display: table-cell;
    font-size: $font-menu;
    // font-weight: 500;
}

.catalogo_header_tittle_disabled {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    pointer-events: none;
}
.catalogo_header_tittle_disabled > p {
    overflow: hidden;
    white-space: nowrap;
}
.catalogo_header_tittle {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    pointer-events: none;
}
.catalogo_header_tittle > p {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer !important;
}
.catalogo_header_check {
    // height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}
.catalogo_header_check_input{
    // height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}

.catalogo_header_tittle::after{
    content: "";
    display: block;
    pointer-events: initial;
    background-color: transparent;
    border-radius: 2px;
    width: 10px;
    height: 40px;
    top: 0px;
    left: calc(100% - 5px + var(--rowleft));

    justify-content: end;
    position: absolute;
}
.catalogo_header_tittle::before{
    content: "";
    display: block;
    pointer-events: none;
    background-color: transparent;
    border-radius: 2px;
    width: 1px;
    height: var(--rowlist);
    top: 0px;
    left: calc(100% + var(--rowleft));

    justify-content: end;
    position: absolute;
}
.catalogo_header_tittle:hover::after{
    background-color: $primary;
}
.catalogo_header_tittle:active::before{
    background-color: $primary;
}

.catalogo_row {
    display: table-row-group;

    border-color: inherit;
    border-top-color: $primary-black;
    border-left-color: $primary-black;
}
//regla para evitar desplazar el primer item de la lista al aparecer el margen
.catalogo_row > div:first-child {
    // padding-left: 11px;
    padding-left: 5px;
}
//solo el primer elemento del padre
.catalogo_row:hover > div:first-child {
    padding-left: 5px;
}
.catalogo_row_dark_theme {
    border-color: inherit;
    border-left-color: $primary-black;
    border-right-color: $primary-black;
    border-top-color: $primary-black;
}
.catalogo_row_light_theme {
    border-color: inherit;
    border-left-color: $primary-white;
    border-right-color: $primary-white;
    border-top-color: $primary-white;
}
.catalogo_row_dark_theme:hover {
    @include theme-component-hover($dark-theme: true);
}
//reglas para aparecer margen al pasar el raton sobre el elemento
.catalogo_row_dark_theme:hover :first-child{
    // border-left-width: 1px;
    // border-left-color: $border-color-select-gray-dark;
    border-color: none;
}
.catalogo_row_dark_theme:hover :last-child{
    // border-right-width: 1px;
    // border-right-color: $border-color-select-gray-dark;
    border-color: none;
}
.catalogo_row_light_theme:hover {
    @include theme-component-hover($dark-theme: false);
}
//reglas para aparecer margen al pasar el raton sobre el elemento
.catalogo_row_light_theme:hover :first-child{
    // border-left-width: 1px;
    // border-left-color: $border-color-select-gray-light;
    border-color: none;
}
.catalogo_row_light_theme:hover :last-child{
    // border-right-width: 1px;
    // border-right-color: $border-color-select-gray-light;
    border-color: none;
}
.catalogo_row_light_selected {
    @include theme-component-selected($dark-theme: false);
}
.catalogo_row_dark_selected {
    @include theme-component-selected($dark-theme: true);
}

.catalogo_item {
    padding-left: 5px;
    padding-right: 5px;
    transition: width, background-color 0.4s;
    display: table-cell;
    vertical-align: middle;
    
    border-color: inherit;
    border-collapse: collapse;
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 0px;
    border-bottom-width: 1px;
    border-right-width: 0px;
}
.catalogo_item > p {
    overflow: hidden;
    white-space: nowrap;
}

.catalogo_item_dark_theme:hover  {
    @include theme-component-selected($dark-theme: true);
}
.catalogo_item_light_theme:hover  {
    @include theme-component-selected($dark-theme: false);
}

.catalogo_item_selected {
    background-color: red !important;
}


.catalogo_controllers {
    grid-area: controllers;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;

    margin-bottom: 10px;
    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-left-width: 1px;
    border-right-width: 1px;
}