@import '../../../../../styles/base/settings';

/******************************************************************************************************************/
.new_publication_title {
    padding: 30px 30px 0px 30px;
    text-align: center;
}

.new_publication_container {
    display: flex;
    justify-content: center;
    //padding: 30px 50px 50px 50px;
}
.new_publication_format {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    width: 100%;
}

.new_publication_refaccion {
    padding: 20px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    color: rgb(179, 179, 179);
    fill: rgb(179, 179, 179);
    transition: .1s;
    p {
        font-size: 20px;
        font-weight: 500;
    }
}
.new_publication_refaccion:hover {
    box-shadow: 1px 1px 2px 2px $color-azul;
    color: $color-azul;
    fill: $color-azul;
}
.cuadro_proximamente {
    font-size: 22px;
    font-weight: 600;
    color: $color-azul;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/*.new_publication_refaccion:hover {
    box-shadow: 1px 1px 2px 2px rgb(4, 115, 206);
}*/
.new_publicatio_icons_style {
    padding: 15px;
}
.new_publication_refaccion_icon {
    height: 145px;
}
/*.new_publication_refaccion_text {
    text-align: center;
    //color: rgb(4, 115, 206);
    color: rgb(221, 221, 221);
    user-select: none;  /* NO PERMITIR SELECCIONAR TEXTO 
}*/
.new_publication_carro {
    padding: 20px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    color: rgb(179, 179, 179);
    fill: rgb(179, 179, 179);
    transition: .1s;
    p {
        font-size: 20px;
        font-weight: 500;
    }
}
.new_publication_carro:hover {
    box-shadow: 1px 1px 2px 2px $color-principal;
    color: $color-principal;
    fill: $color-principal;
}
.new_publication_carro_icon {
    padding: 15px;
}
.new_publication_carro_icon_container {
    height: 145px;
}
/******************************************************************************************************************/

.publication_modal_web {
    overflow-y: scroll;
    //height: 600px;
}

@media (max-width: 580px) {
    .new_publication_container {
        margin: 10px 5px 10px 5px;
    }
    .new_publication_format {
        gap: 30px;
        grid-template-columns: 1fr;
        padding: 25px;
    }
    .new_publication_refaccion_icon {
        height: 115px;
    }
    .new_publicatio_icons_style {
        padding: 0px;
        height: 110px;
    }
    .new_publication_carro_icon {
        padding: 0px;
        height: 110px;
    }
    .new_publication_carro_icon_container {
        height: 115px;
    }
    .new_publicaction_refaccion_soon {
        top: 200px;
        left: 58px;
        text-align: center;
    }
    .new_publicaction_refaccion_soon {
        top: 40%;
    }
    .new_publication_refaccion {
        display: unset;
        align-items: unset;
    }
}