@import './base/settings';
@import './base/base';
@import "~react-image-gallery/styles/scss/image-gallery.scss";


//# SCSS REACT IMAGE GALLERY
// @import "~react-image-gallery/styles/scss/image-gallery.scss";

//# CSS REACT IMAGE GALLERY
// @import "~react-image-gallery/styles/css/image-gallery.css";

/************   ESTILOS PRINCIPALES DEL HEADER ( NAVBAR PRINCIAPL DEL COMPONENTE )   ************/
.app_header { ///////////////////   CONTENEDOR DEL HEADER (NAVBAR)   ///////////////////
    display: flex;
    align-items: center;
    height: 71px;
}
.app_header_navbar{ ////////////////   CONTENEDOR DEL HEADER (NAVBAR)   ////////////////
    margin: auto;
    width: 95%;
    max-width: 1366px;
}
.app_header_navbar_logo{  ////////  TAMAÑO DEL LOGO PRINCIPAL (AUTOS X PARTES)  ////////
    height: 70px;
}
.app_header_navbar_logo_letra_x { //// COLOR PARA LA LINEA CRUZADA DE LA X DEL LOGO ////
    fill: #ff6700;
}
.app_header_navbar_icon_menu{ /// ICONO DE MENU (APARECE AL HACER PEQUEÑA LA PAGINA) ///
    height: 25px;
}
.app_header_navbar_icon_user{  /// ICONO DE uSUARIO (DONDE APARECE "INICIAR SESION") ///
    height: 35px;
}
.app_header_nombre {
    font-size: $font-texto-chico;
    font-weight: 500;
    margin-top: 10px;
}
.app_header_notification{
    margin: 2px;
    height: 36px;
    border-radius: 5px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.app_header_notification_cards {
    transition: box-shadow .1s;
}
.app_header_notification_cards:hover {
    box-shadow: 1px 1px 6px 1px rgb(56, 53, 53);
    cursor: pointer;
}
.app_header_number_show {
    font-size: 10px;
    padding-left: 5px;
    padding-top: 2px;
    position: relative;
    left: 1px;
    width: 10px;
    height: 13px;
    top: 8px;
    color: white;
    border-radius: 15px;
    opacity: 1;
    transition: all 0.2s;
    background-color: red;
}
.app_header_number_hide {
    left: 5px;
    top: 6px;
    opacity: 0;
    width: 0px;

    transition: all 0.2s;
}

.app_header_container_cargando {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app_header_cargando {
    display: block;
    align-self: auto;
    margin-bottom: 5px;
}

/************   ESTILOS PRINCIPALES DEL HEADER ( NAVBAR PRINCIAPL DEL COMPONENTE   *************/


.app_footer {    ////////////////    CONTENEDOR PRINCIPAL DEL FOOTER    ////////////////
    //padding-top: 10px;
    background-color: black;
    color: white;
}
.app_footer_container {  /////////  CONTENEDOR PARA LOS COMPONENTES DE FOOTER  /////////
    margin: auto;
    width: 90%;
    max-width: 1366px;
}
.app_footer_links { /////////  CONTENEDOR PARA EL LOGO Y LOS LINKS DEL FOOTER  /////////
    display: grid;
    padding-top: 40px;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-areas: "logo-footer";
}
.app_footer_links .logo-footer{ /////  DIV´S PARA EL ACOMODO DEL LOGO Y LOS LINKS  /////
    grid-area: logo-footer;
    display: flex;
    justify-content: center;
}
.app_footer_links .links-1{
    grid-area: links-1;
    display: block;
}
.app_footer_links .links-2{
    grid-area: links-2;
}
.app_footer_links .links-3{
    grid-area: links-3;
}
.app_footer_links div > p {   ////////  ESTILO PARA LA SEPARACION DE LOS LINKS  ////////
    margin-bottom:  15px;
}
.app_footer_logo_blanco {  //////////  ESTILO PARA EL LOGO BLANCO DEL FOOTER  //////////
    fill: white;
    height: 100px;
}
.app_footer_redes{ /////////  CONTENEDOR PARA LOS ICONO DE LAS REDES SOCIALES  /////////
    text-align: center;
}
.app_footer_redes {  ///////  ESTILO PARA LOS ICONOS DE LAS REDES SOCIALES  ///////
    display: flex;
    justify-content: center;
}
.app_footer_redes_iconos {
    height: 50px;
    fill: white;
    margin: 40px 20px 40px 20px;
}
.app_footer_cotiza_favorito > h2{////  SEPARACION DE ESPACIO DEL APARTADO "CORIZA"  ////
    margin-bottom: 20px;
}
.app_footer_derechos_autor { ///////  ESTILO PARA EL APARTADO DERECHOS DE AUTOR  ///////
    text-align: center;
    padding-bottom: 60px;
}
.app_footer_marcas {
    text-align: center;
    margin-bottom: 40px;
    font-size: 13px;
}

@media (max-width: 850px) {  /////////    RESPONSIVE ( MEDIA QUERY ) FOOTER    //////////
    .app_footer_links {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "logo-footer  logo-footer  logo-footer"
                             "links-1      links-2      links-3    ";
        font-size: 15px;
    }
    .app_footer_logo_blanco {
        height: 70px;
        margin-right: 0px;
    }
    .app_footer_redes > svg{
        height: 40px;
        margin: 30px 10px 30px 10px;
    }
    .app_footer_redes_iconos {
        height: 40px;
        margin: 10px 20px 10px 20px;
    }
    .logo-footer{
        display: flex;
        justify-content: center;
    }
    .app_footer_cotiza_favorito {
        font-size: 14px;
    }
    .app_footer_cotiza_favorito > h2{
        font-size: 20px;
    }
    .app_footer_derechos_autor {
        font-size: 14px;
        padding: 40px 0px 50px 0px;
    }
    .app_footer_marcas {
        font-size: 11px;
        margin-top: 30px;
        margin-bottom: 0px;
    }
}/****************     ESTILOS pRINCIAPLES PARA EL FOOTER ( PIE DE PAGINA )     *****************/

@media (max-width: 300px) {
    .app_header_navbar{ ////////////////   CONTENEDOR DEL HEADER (NAVBAR)   ////////////////
        width: 100%;
    }
    .app_footer_redes_iconos {
        margin: 10px 10px 10px 10px;
    }
    .app_footer_logo_blanco {
        height: 60px;
    }
    .app_footer_marcas {
        font-size: 10px;
        margin-top: 25px;
    }
    .app_footer_derechos_autor {
        font-size: 12px;
        padding: 30px 0px 50px 0px;
    }
}
