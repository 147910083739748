@import '../../../styles/base/settings';

.notfound_principal {
    margin: 0px;
    padding: 0px;
    background-color: $primary-white;
    height: 666px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.notfound_grid {
    display: grid;
    grid-template-columns: 100px 200px 50px;
    grid-template-rows: 70px 50px 50px;
    grid-template-areas: "icon          title         title "
                         "icon          subtitle      subtitle "
                         "icon          message       message   ";
}

.notfound_icon {
    grid-area: icon;
}

.notfound_title {
    grid-area: title;
    font-size: $font-gigant;
    margin-left: 20px;
}

.notfound_subtitle {
    grid-area: subtitle;
    font-size: $font-big;
    margin-left: 20px;
}

.notfound_messsage {
    grid-area: message;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
}

.notfound_messsage > a {
    display: block;
    align-self: flex-end;
    font-size: $font-size;
    height: 16px;
}