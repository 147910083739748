@import '../../../../../styles/base/settings';

.navbar_main {
    height: 50px;
    width: 100vw;
    min-width: 1024px;

    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 

    display: grid;
    grid-template-columns: 1fr 510px;
    gap: 0px;

    //box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
    
    position: relative;
}
.navbar_light_theme {
    background-color: $background-light;
    color: $text-color-light;
    transition: background-color 0.4s;
}
.navbar_dark_theme {
    background-color: $background-dark;
    color: $text-color-dark;
    transition: background-color 0.4s;
} 
.navbar_left{
    display: flex;
    margin-left: 10px;
}
.navbar_logo{
    margin-top: 0px;
    width: var(--logowidth);
    transition: width 0.4s;
    overflow: hidden;
}
.navbar_logo_small{
    margin-top: 6px;
    width: 50px;
    transition: width 0.4s;
    overflow: hidden;
}
.navbar_title{
    font-size: $font-big;
    margin-top: 14px;
    overflow: hidden;
    white-space: nowrap;
}
.navbar_right{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.navbar_buttons{
    width: 150px;
    display: flex;
}
.navbar_user{
    height: 40px;
    width: 180px;
    overflow: hidden;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbar_usericon{
    padding-right: 10px;
    margin: 2px;
    height: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar_usertext{
    padding-right: 10px;
    padding-top: 2px;
    font-size: $font-size;
}
.navbar_button{
    margin: 2px;
    height: 36px;
    border-radius: 5px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        background-color: $primary;
        color: $primary-white;
        transition: all ease 0.3s;
        cursor: pointer;
    }
}
.navbar_button i {
    display: block;
    align-self: auto;
}
.navbar_number_show {
    font-size: $font-little;
    padding-left: 5px;
    padding-top: 2px;
    position: relative;
    left: 1px;
    width: 10px;
    height: 13px;
    top: 8px;
    color: white;
    border-radius: 15px;
    opacity: 1;
    transition: all 0.2s;
    background-color: red;
}
.navbar_number_hide {
    left: 5px;
    top: 6px;
    opacity: 0;
    width: 0px;

    transition: all 0.2s;
}

.navbar_alert{
    width: 50px;   
}
.navbar_msgs{
    width: 50px;   
}
.navbar_notifications{
    width: 50px;   
}
.navbar_darkmode{
    height: 40px;
    width: 130px;
    padding-top: 5px;
    overflow: hidden;

    display: flex; 
    // justify-content: center;
    align-items: center;
}
.navbar_darkmode_label{
    padding-top: 3px;
}
.navbar_logout{
    height: 40px;
    width: 50px;
    overflow: hidden;
}
.navbar_darkmode label{
    padding-bottom: 4px;
    font-size: $font-size;
    cursor: pointer;
}

.navbar_icon {
    margin-left: 10px;
    margin-top: 10px;
    width: 148px;
    transition: width 0.4s;
}
.navbar_icon_small {
    margin-left: 8px;
    margin-top: 7px;
    width: 25px;
    //transition: margin-left 0.4s;
}
.navbar_label_check {
    padding-top: 5px;
    margin-right: 10px; 
}

.navbar_modal{
    width: 300px;
}

.navbar_modal_grid {
    width: 300px;
    font-size: $font-size;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;

    display: grid;
    grid-template-columns: 50px 240px 5px;
    grid-template-rows: repeat(4,18px);
    border-radius: 5px;

    &:hover{
        background-color: $primary;
        color: $primary-white;
        transition: background-color ease 0.3s;
        cursor: pointer;
    }
    &:hover .navbar_modal_date{
        color: $primary-white;
    }
}
.navbar_modal_grid_light{
    background-color: $background-light;

}
.navbar_modal_grid_dark{
    background-color: $background-dark;
}
.navbar_modal_icon {
    grid-column: 1 / -3;
    grid-row: 1 / 4;
    // font-size: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar_modal_icon_orange {
    color: $alert-orange;
}
.navbar_modal_icon_purple {
    color: $alert-purple;
}
.navbar_modal_icon_red {
    color: $alert-red; 
}
.navbar_modal_icon_green {
    color: $alert-green;
}
.navbar_modal_icon_blue {
    color: $alert-blue;
}
.navbar_modal_text {
    padding-right: 10px;
    font-size: $font-size;
    grid-column: -3 / -2;
    grid-row: 1 / 4;
    overflow-y: hidden;
    overflow-x: hidden;
}
.navbar_modal_text_msg {
    padding-right: 10px;
    margin-bottom: 8px;
    font-size: $font-size;
    grid-column: -3 / -2;
    grid-row: 2 / 4;
    overflow-y: hidden;
    overflow-x: hidden;
}
.navbar_modal_title {
    padding-right: 10px;
    font-size: $font-size;
    grid-column: -3 / -2;
    grid-row: 1 / 2;
    overflow-y: hidden;
    overflow-x: hidden;
}

.navbar_modal_active {
    grid-column: -2 / -1;
    grid-row: 1 / 5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.navbar_modal_active_red {
    //color: $alert-red;
    background-color: $alert-red;    
}
.navbar_modal_active_blue {
    //color: $alert-blue;
    background-color: $alert-blue;
}
.navbar_modal_active_green {
    //color: $alert-green;
    background-color: $alert-green;
}
.navbar_modal_date {
    padding-left: 5px;
    padding-top: 1px;
    grid-column: 1 / -1;
    grid-row: 4 / 5;
}
.navbar_modal_date_light{
    color: $text-color-gray-light;
}
.navbar_modal_date_dark{
    color: $text-color-gray-dark;
}

.navbar_submodal_message{
    width: 500px;
}
.navbar_submodal_title{
    display: flex;
    color: $alert-blue;
}
.navbar_submodal_title p{
    padding-left: 8px;
    padding-top: 13px;
    padding-bottom: 20px;
    height: 15px;
}
.navbar_submodal_text {
    padding-top: 20px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 20px;
}

.icon {
    color: red;
}