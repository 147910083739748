
.nosotros_title_container {
    margin: auto;
    width: 95%;
    max-width: 1360px;
    padding: 50px 50px 0px 50px;
    font-size: 48px;
}
.nosotros_container_format {
    margin: auto;
    width: 95%;
    max-width: 1366px;
    gap: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.nosotros_container_azul {
    background-color: #004e98;
    color: white;
    margin-bottom: 70px;
    padding: 50px;
}
.nosotros_container_blanco {
    margin-bottom: 70px;
    padding: 50px;
}
.nosotros_container_text{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

@media (max-width: 1480px) {
    .nosotros_title_container {
        width: unset;
        font-size: 38px;
    }
}

@media (max-width: 800px) {
    .nosotros_title_container {
        padding: 30px 40px 0px 40px;
    }
    .nosotros_container_format {
        width: 100%;
        gap: 30px;
        grid-template-columns: 1fr;
    }
    .nosotros_container_blanco {
        margin-bottom: 0px;
        padding: 40px;
    }
    .nosotros_container_azul {
        margin-bottom: 0px;
        padding: 40px;
    }
}

@media (max-width: 300px) {
    .nosotros_title_container {
        font-size: 35px;
    }
    .nosotros_container_blanco {
        padding: 30px;
    }
    .nosotros_container_azul {
        padding: 30px;
    }
}