
.publicacion_info_container {
    margin: auto;
    max-width: 1260px;
    padding: 45px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    margin-bottom: 20px;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "imagenes     titulo  "
                         "caracteris   vendedor"
                         "descrip      div     "
                         "preguntas    div     ";
}
.publicacion_info_imagenes{
    margin: auto;
    grid-area: imagenes;
}
.publicacion_info_images_format {
    max-width: 800px;
    height: 500px;
}
.publicacion_images_carrusel {
    max-width: 100%;
    height: 450px;
}
.publicacion_info_container_icon {
    height: 45px;
    width: 45px;
}
.publicacion_info_icon {
    width: 50px;
}
.publicacion_info_marca {
    font-size: 35px;
    margin-left: 15px;
}
.publicacion_info_titulo {
    grid-area: titulo;
}
.publicacion_info_titulo_card {
    /*height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;*/
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.publicacion_info_caracteristicas {
    //margin-top: 45px;
    grid-area: caracteris;
}
.publicacion_info_subtitulo {
    font-size: 34px;
    padding-bottom: 30px;
}
.publicacion_info_caracteristicas_format {
    display: grid;
    grid-template-columns: 1fr 1fr;
    div {
        padding: 25px;
        border: 1px solid #d4d4d4;
        background-color: #ececec;
    }
}
.publicacion_info_seller{
    //margin-top: 25px;
    grid-area: vendedor;
}
.publicacion_info_description{
    grid-area: descrip;
}
.publicacion_info_descripcion_size {
    font-size: 18px;
    font-weight: 400;
    white-space: pre-line;
}
.publicacion_sin_respuesta {
    color: rgb(255, 38, 0);
}
.publicacion_info_preguntas {
    grid-area: preguntas;
}

@media ( max-width: 1180px ) {
    .publicacion_info_container {
        margin-bottom: unset;
        padding: 35px;
        grid-template-columns: 1fr;
        gap: 40px;
        grid-template-areas: "imagenes  "
                             "caracteris"
                             "descrip   "
                             "vendedor  "
                             "titulo    "
                             "preguntas ";
    }
}

@media ( max-width: 1000px ) {
    .publicacion_info_images_format {
        display: flex;
        justify-content: center;
        height: unset;
    }
    .publicacion_info_description{
        top: unset;
        position: unset;
    }
    .publicacion_info_caracteristicas_format{
        div {
            padding: 15px;
        }
    }
    .publicacion_images_carrusel {
        height: 350px;
    }
    .publicacion_info_slider {
        width: 510px;
    }
    .publicacion_info_caracteristicas {
        margin-top: unset;
    }
    .publicacion_info_titulo_card {
        /*height: unset;
        display: unset;
        align-items: unset;
        justify-content: unset;*/
        min-height: unset;
        display: block;
    }
    .publicacion_info_description {  
        margin-top: unset;
    }
}

@media ( max-width: 600px ) {
    .publicacion_info_container {
        gap: 30px;
    }
    .publicacion_images_carrusel {
        height: 200px;
    }
    .publicacion_info_slider {
        width: 300px;
    }
    .publicacion_info_marca {
        font-size: 25px;
    }
    .publicacion_info_container_icon {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
    }
    .publicacion_info_icon {
        width: 30px;
    }
    .publicacion_info_subtitulo {
        font-size: 25px;
        padding-bottom: 30px;
    }
    .publicacion_info_descripcion_size {
        font-size: 20px;
    }
}

@media ( max-width: 400px ) {
    .publicacion_info_slider {
        width: 250px;
    }
}

@media ( max-width: 300px ) {
    .publicacion_info_slider {
        width: 200px;
    }
    .publicacion_images_carrusel {
        height: 150px;
    }
    .publicacion_info_caracteristicas_format {
        div {
            padding: 10px;
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}