
/*  */

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
    background-color: rgba(255, 255, 255, 0.788);
    width: 40px;
    height: 90px;
    z-index: 1;
}

.custom-indicator {
    bottom: -65px;
}

.custom-indicator li {
    width: 60px;
    height: 50px;
}

.slider-dots {
    width: 60px;
    height: 50px;
    object-fit: cover;
}
.slider-dots-2 {
    width: 60px;
    height: 50px;
    object-fit: cover;
}

.slick-next {
    right: 0px;
}
.slick-prev {
    left: 0px;
}

.click-zoom input[type=checkbox] {
    display: none
}
  
.click-zoom img {
    margin: 100px;
    transition: transform 0.25s ease;
    cursor: zoom-in
}
  
.click-zoom input[type=checkbox]:checked~img {
    transform: scale(3);
    cursor: zoom-out
}


@media ( max-width: 600px ) {
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        width: 20px;
        height: 70px;
    }
    .custom-indicator {
        bottom: -75px;
    }
    .custom-indicator li {
        width: 40px;
        height: 30px;
    }
    .slider-dots {
        width: 40px;
        height: 30px;
    }
}