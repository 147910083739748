@import '../../../../../styles/base/settings';

.shopping_wait {
    height: 666px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shopping_main {
    padding: 30px 60px 30px 60px;
}

.shopping_menu {
    max-width: 1350px;
    margin: 0 auto;
}

.shopping_section {
    margin: 0 auto;
    max-width: 1350px;
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.shopping_format {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
}

.shopping_titulo_principal {
    font-size: $font-titulo-principal;
}

.shopping_image {
    height: 200px;
    overflow: hidden;
}

.shopping_datos {
    padding: 10px 20px 15px 20px;
    font-size: $font-texto-grande;
}

.shopping_datos_nombre_producto {
    font-size: $font-descripcion-precio;
    font-weight: 500;
    margin-bottom: 10px;
}

.shopping_datos_precio {
    color: rgb(0, 93, 199);
    font-size: $font-subtitulo-chico;
    font-weight: 500;
    margin-top: 10px;
}

.shopping_datos_estado {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    padding: 10px 0px 10px 0px;
}

.shopping_search {
    max-width: 1400px;
    margin: 20px 0px 40px 0px;
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 350px 120px 1fr 1fr;
    grid-template-areas: "input    button    filter    numberShopping";
}
.shopping_search_input {
    grid-area: input;
}
.shooping_search_button {
    grid-area: button;
}
.shopping_search_filter {
    grid-area: filter;
    font-size: $font-texto-grande;
}
.shooping_search_numberShopping {
    grid-area: numberShopping;
    font-size: $font-texto-grande;
}

.shooping_button {
    text-align: center;
}

@media (max-width: 1170px) {
    .shopping_section {
        grid-template-columns: 1fr 1fr 1fr;
    }
    /*.shopping_modal_pregunta_web {
        padding: 0px;
    }*/
}
@media (max-width: 850px) {
    .shopping_section {
        grid-template-columns: 1fr 1fr;
    }
    .shopping_search {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "input             input         "
                             "button            filter        "
                             "numberShopping    numberShopping";
    }
    .shopping_datos_nombre_producto {
        font-size: $font-texto-grande;
        //margin-bottom: 10px;
    }
}
@media (max-width: 550px) {
    .shopping_section {
        grid-template-columns: 1fr;
    }
    .shopping_titulo_principal {
        font-size: 30px;
    }
    .shopping_datos {
        padding: 10px 20px 5px 20px;
    }
    .shopping_search {
        margin: 10px 0px 15px 0px;
        gap: 12px;
        grid-template-columns: 1fr;
        grid-template-areas: "input         "
                             "button        "
                             "filter        "
                             "numberShopping";
    }
    .shopping_main {
        padding: 30px 30px 30px 30px;
    }
    .shopping_image {
        height: 150px;
    }
}

/*.shopping_modal_pregunta_web {
    min-width: 300px;
    max-width: 500px;
    border-radius: 5px;
    -moz-border-radius: 15px;
    font-size: 14px;

    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image     image"
                         "titulo    titulo"
                         "dato1     dato1"
                         "dato2     dato2"
                         "opcion1   opcion2"
                         "opcion3   opcion3"
                         "cerrar    cerrar";
}
.shopping_modal_pregunta_cerrar{
    grid-area: cerrar;
}
.shopping_modal_pregunta_image{
    grid-area: image;
}
.shopping_modal_pregunta_titulo {
    grid-area: titulo;
    text-align: center;
    font-size: 30px;
}
.shopping_modal_pregunta_dato1 {
    grid-area: dato1;
    text-align: center;
    font-size: 20px;
}
.shopping_modal_pregunta_dato2 {
    grid-area: dato2;
    text-align: center;
    font-size: 15px;
}
.shopping_modal_pregunta_opcion1 {
    grid-area: opcion1;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.shopping_modal_pregunta_opcion2 {
    grid-area: opcion2;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.shopping_modal_pregunta_opcion3 {
    grid-area: opcion3;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.shopping_modal_pregunta_cerrar {
    grid-area: cerrar;
    display: flex;
    justify-content: flex-end;
    align-items: center;
} 

@media (max-width: 700px) {
    .shopping_modal_pregunta_web {
        padding: 0px;
    }
}
@media (max-width: 400px) {
    .shopping_modal_pregunta_web {
        padding: 0px;
    }
    .shopping_modal_pregunta_titulo {
        font-size: 25px;
    }
}*/