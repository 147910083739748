@import '../../../../../styles/base/settings';

.myaccount_wait {
    height: 666px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myaccount_menu_border {
    //background-color: aqua;
    //border: 1px solid rgba(0, 0, 0, 0.219);
    max-height: 600px;
}

.package_modal_web {
    display: flex;
    //gap: 20px;
    //grid-template-columns: 1fr 1fr;
    min-width: 650px;
    max-width: 900px;
}
.package_modal_movil {
    display: inline;
}

.package_modal_item_web {
    width: 100%;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    -moz-border-radius: 15px;
    font-size: 14px;

    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "titulo   titulo"
                         "icono    icono"
                         "dato1    dato1"
                         "dato2    dato2"
                         "opcion   opcion";
}

.package_modal_item_movil {
    width: 100%;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    -moz-border-radius: 15px;
    margin-top: 10px;
    font-size: 14px;
}

.package_modal_titulo {
    grid-area: titulo;
    text-align: center;
}
.package_modal_icono {
    grid-area: icono;
    text-align: center;
}
.package_modal_dato1 {
    grid-area: dato1;
}
.package_modal_dato2 {
    grid-area: dato2;
}
.package_modal_opcion {
    grid-area: opcion;
    display: flex;
    justify-content: center;
}

.package_modal_button{
    text-align: end;
}