@import '../../../../../../../styles/base/settings';

.cuenta_main {
    //height: 700px;
    margin: 0px 30px 0px 30px;
}

.cuenta_title {
    max-width: 1000px;
    margin: 0 auto;
    font-size: $font-titulo-principal;
    padding-bottom: 15px;
}

.cuenta_section{
    margin: 0 auto;
    max-width: 1000px;
    margin-bottom: 20px;
    border-radius: 5px;
    -moz-border-radius: 15px;
    cursor: pointer;
    box-shadow: 1px 1px 2px 2px rgba(78, 66, 66, 0.219);
    transition: 0.1s;
}
.cuenta_section:hover{
    box-shadow: 1px 1px 2px 2px rgb(41, 37, 37);
}

/*.new_publication_carro {
    padding: 20px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    cursor: pointer;
}
.new_publication_carro:hover {
    box-shadow: 1px 1px 2px 2px #ff6700;
}*/

.cuenta_section_format {
    padding: 20px;
    display: grid;
    grid-template-columns: 95px 1fr 25px;
    grid-template-areas: "icono  datos  opcion";
}
.cuenta_section_icono {
    grid-area: icono;
    display: flex;
    align-items: center;
}
.cuenta_section_datos {
    grid-area: datos;
    font-size: $font-texto-grande;
}
.cuenta_section_opcion {
    grid-area: opcion;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {
    .cuenta_section_format {
        grid-template-columns: 1fr 25px;
        grid-template-areas: "icono  icono "  
                             "datos  opcion";
    }
    .cuenta_main {
        margin: 0px -10px 0px -15px;
    }
    .cuenta_section_icono {
        justify-content: center;
    }
}