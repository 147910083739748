
.find_buscador_container{
    background-color: #004e98;
    padding: 25px;
}

.find_buscador_format{
    margin: auto;
    max-width: 1350px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

.find_publicaciones_container { ////////////////////////////////////////////////////////////////////// Estilos
    margin: auto;
    display: flex;
    max-width: 1350px;
    padding-top: 30px;
    padding-bottom: 50px;
}

.find_publicaciones_format{
    margin: auto;
    max-width: 1350px;
    padding-bottom: 50px;
    padding-right: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

.find_publicacion_card {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    height: 100%;
    cursor: pointer;
}
.find_publicacion_card:hover {
    box-shadow: 0px 0px 25px 4px rgb(0 0 0 / 49%);
}
.find_publicacion_card_title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 6px;
    color: rgb(0, 93, 199);
    display: flex;
    align-items: center;
}
.find_publicacion_card_descripcion {
    font-size: 16px;
    margin-top: 6px;
    div {
        color: rgb(0, 93, 199);
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 8px;
    }
}
.find_publicacion_card_image {
    height: 250px;
}

.find_publicaciones_sin_resultados_cargando {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 650px;
    width: 100%;
}

.find_publicaciones_sin_resultados_busqueda {
    margin-top: 20px;
    padding: 20px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.219);
}
.find_publicaciones_sin_resultados_cargando2 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.find_publicaciones_sin_resultados_datos {
    padding: 20px 0px 20px 0px;
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 15px;
    align-items: center;
}

@media (max-width: 1000px) {
    .find_publicaciones_container {
        padding: 20px 30px 30px 30px;
    }
    .find_publicaciones_format{
        padding-right: unset;
    }
}

@media (max-width: 850px) {
    .find_buscador_container{
        padding: 22px;
    }
    .find_buscador_format{
        grid-template-columns: 1fr;
    }
    .find_publicaciones_format{
        grid-template-columns: repeat(2, 1fr);
    }
    .find_publicaciones_sin_resultados_cargando {
        height: unset;
    }
}

@media (max-width: 550px) {
    .find_publicaciones_format{
        grid-template-columns: 1fr;
    }
    .find_publicaciones_sin_resultados_datos {
        display: grid;
        grid-template-columns:1fr;
        gap: 10px;
    }
}