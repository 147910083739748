
/**********  ESTILOS PARA EL STEPPER DE PUBLICACION DE CARRO  **********/
.new_car_modal_main {
    padding: 30px;
}
.new_car_modal_content {
    display: flex;
    justify-content: center;
    padding: 30px 50px 50px 50px;
}
.new_car_modal_images_container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

////////////////////////**************************/////////////////////////////
.new_publication_spare_title {
    font-size: 24px;
    flex: 1;
}
.new_publication_spare_format {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "datos     datos  "
                         "imagen    descrip";
}
.new_publication_spare_format_datos {
    grid-area: datos;
    text-align: center;
    color: rgb(0, 93, 199);
}
.new_publication_spare_format_imagen {
    grid-area: imagen;
    margin-right: 10px;
}
.new_publication_spare_format_descrip {
    grid-area: descrip;
    margin-left: 10px;
    padding-top: 32px;
}
.new_publication_spare_compa {
    margin-top: 40px;
    margin-left: 20px;
}
.new_publication_spare_image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #75757575;
    max-width: 100%;
}
.new_publication_spare_images_container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}
////////////////////////**************************/////////////////////////////

/**********  ESTILOS PARA EL MODAL VER PUBLICACION  **********/
.sale_modal_container {
    padding: 50px 50px 50px 50px;
    overflow-y: scroll;
}
.sale_modal_format {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 40px;
    max-width: 1260px;
    grid-template-areas:"imagenes    titulo     "
                        "caracteris  descripcion";
}
.sale_modal_images{
    grid-area: imagenes;
    margin: auto;
}

.sales_modal_titulo_principal {
    grid-area: titulo;
    border: 1px solid #d4d4d4;
    padding: 20px;
    display: flex;
    align-items: center;
}
.sales_modal_caracteristicas {
    grid-area: caracteris;
}
.sales_modal_descripcion {
    grid-area: descripcion;
    border: 1px solid #d4d4d4;
    padding: 20px;
}
.sales_modal_titulo_style {
    font-size: 28px;
    padding-left: 15px;
    color: rgb(0, 93, 199);
}
.sales_modal_icon {
    height: 48px;
}
.sale_modal_images_carrusel {
    width: 100%;
    height: 400px;
}
.sale_modal_images_slider {
    width: 100%;
}

.publicacion_rechazada_format {
    background-color: #D32F2F;
    padding: 20px;
    color: white;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 50px 1fr 100px;
    grid-template-areas: "icono mensaje boton";
}
.publicacion_rechazada_icono {
    grid-area: icono;
    display: flex;
    align-items: center;
}
.publicacion_rechazada_mensaje {
    grid-area: mensaje;
    display: flex;
    align-items: center;
}
.publicacion_rechazada_boton {
    grid-area: boton;
    display: flex;
    align-items: center;
}

@media (max-width: 1250px) {
    .sale_modal_format {
        grid-template-columns: 1fr;
        gap: 25px;
        grid-template-areas:"titulo     "
                            "imagenes   "
                            "caracteris "
                            "descripcion";
    }
}

/**********  MEDIAS QUERYS PARA LOS MODALS  **********/
@media (max-width: 1000px) {
    .sale_modal_container {
        padding: 40px;
    }
    .sale_modal_images{
        margin: auto;
    }
    .sales_modal_titulo_principal {
        justify-content: center;
    }
    .sales_modal_titulo_style {
        font-size: 30px;
    }
    .sales_modal_icon {
        height: 40px;
    }
    .sale_modal_images_carrusel {
        height: 350px;
    }
}

@media (max-width: 850px) {
    .new_publication_spare_compa {
        margin-top: 20px;
        margin-left: 0px;
    }
    .new_publication_spare_format {
        grid-template-columns: 1fr;
        grid-template-areas: "datos  "
                             "imagen "
                             "descrip";
    }
    .new_publication_spare_format_imagen {
        margin-right: 0px;
    }
    .new_publication_spare_format_descrip {
        grid-area: descrip;
        margin-left: 0px;
        padding-top: 20px;
    }
    .new_publication_spare_images_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .new_car_modal_content {
        padding: 20px;
    }
    .new_car_modal_images_container {
        grid-template-columns: 1fr;
    }
    .new_publication_spare_title {
        font-size: 18px;
        padding: 10px;
    }
    .new_publication_spare_images_container {
        grid-template-columns: 1fr;
    }
    .sale_modal_images_slider {
        width: 300px;
    }
    .sale_modal_images_carrusel {
        height: 200px;
    }

    .publicacion_rechazada_format {
        grid-template-columns: 50px 1fr;
        grid-template-areas: "icono mensaje"
                             "icono boton  ";
    }
    .publicacion_rechazada_boton {
        margin-top: 10px;
    }
}

@media (max-width: 470px) {
    .sale_modal_container {
        padding: 25px;
    }
    .sale_modal_format {
        gap: 20px;
    }
    .sales_modal_titulo_principal {
        padding-bottom: 10px;
    }
    .sales_modal_titulo_style {
        font-size: 24px;
    }
    .sales_modal_dato_vendedor {
        margin-top: 5px;
    }
    .sales_modal_dato_secundario1 {
        gap: 0px;
    }
    .sales_modal_dato_secundario1 p {
        font-size: 16px;
        padding-bottom: 10px;
    }
    .sales_modal_estado {
        gap: 0px;
    }
    .sales_modal_estado p {
        font-size: 16px;
    }
    .sale_modal_botones {
        grid-template-columns: 1fr;
        gap: 15px;
    }

}

@media ( max-width: 413px ) {
    .sale_modal_images_slider {
        width: 250px;
    }
    .new_car_modal_content {
        padding: 15px;
    }
    .sale_modal_container {
        padding: 10px;
    }
}