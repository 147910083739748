//colors
$primary: #0d6efd;
$primary-select-gray-dark: darken($color: $primary, $amount: 40);
$primary-select-gray-light: lighten($color: $primary, $amount: 42);

// $primary-background: #001BFF;
$primary-background: black;
$primary-white: lighten($color: $primary-background, $amount: 100);
$primary-black: darken($color: $primary-background, $amount: 100);

$background-primary: darken($color: $primary, $amount: 10);
$background-light: darken($color: $primary-white, $amount: 4);
$background-dark: lighten($color: $primary-black, $amount: 14);

$alert-green: #00C875;
$alert-red: #E2445C;
$alert-blue: #579BFC;
$alert-orange: #FDAB3D;
$alert-purple: #8d84e8;

$text-color-dark: $primary-white;
$text-color-light: $primary-black;
$text-color-gray-light: darken($color: $primary-white, $amount: 80);
$text-color-gray-dark: lighten($color: $primary-black, $amount: 90);

$border-color-gray-light: darken($color: $primary-white, $amount: 12);
$border-color-gray-dark: lighten($color: $primary-black, $amount: 20);
$border-color-select-gray-light: darken($color: $border-color-gray-light, $amount: 20);
$border-color-select-gray-dark: lighten($color: $border-color-gray-dark, $amount: 20);

$background-color-hover-light: darken($color: $primary-white, $amount: 4);
$background-color-hover-dark: lighten($color: $primary-black, $amount: 20);

//font-size 
$font-gigant: 50px;
$font-big: 20px;
$font-menu: 14px;
$font-size: 12px;
$font-little: 10px;

/***********  ESTILOS DE DANY DETERMINADOS  ***********/
$font-titulo-principal: 36px;
$font-subtitulo-grande: 28px;
$font-subtitulo-chico: 24px;
$font-descripcion-precio: 22px;
$font-texto-grande: 18px;
$font-texto-mediano: 16px;
$font-texto-chico: 14px;

$color-principal: #ff6700;
$color-secundario: #004e98;
$color-azul: rgb(0, 93, 199);
$color-blanco: white;
$color-gris: rgba(0, 0, 0, 0.6);
/***********  ESTILOS DE DANY DETERMINADOS  ***********/

//mixins
@mixin theme-text-colors($dark-theme) {
    @if $dark-theme {
        background-color: $background-dark;
        color: $text-color-dark;
    } @else  {
      background-color: $background-light;
      color: $text-color-light;
    }
}
@mixin theme-component-colors($dark-theme) {
    @if $dark-theme {
        background-color: $primary-black;
        border-color: $border-color-gray-dark;
    } @else  {
        background-color: $primary-white;
        border-color: $border-color-gray-light;
    }
}
@mixin theme-component-hover($dark-theme) {
    @if $dark-theme {
        background-color: $background-color-hover-dark;
        border-color: none;
    } @else  {
        background-color: $background-color-hover-light;
        border-color: none;
    }
}
@mixin theme-component-selected($dark-theme) {
    @if $dark-theme {
        background-color: $primary-select-gray-dark;
        color: $primary-white;
    } @else  {
        background-color: $primary-select-gray-light;
        color: $primary-black;
    }
}


