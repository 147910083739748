
.package_modal_container {
    padding: 30px;
    overflow-y: scroll;
}

.package_modal_format {
    display: flex;
}

.package_modal_titulo_principal {
    font-size: 34px;
    margin-bottom: 10px;
}
.package_modal_card_plan_titulo {
    font-size: 20px;
    font-weight: 500;
}

.package_modal_icon_plan {
    width: 150px;
}

.package_modal_card {
    width: 100%;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    -moz-border-radius: 15px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "titulo   titulo"
                         "icono    icono"
                         "dato1    dato1"
                         "dato2    dato2"
                         "opcion   opcion";
}
.package_modal_card_titulo {
    grid-area: titulo;
    text-align: center;
}
.package_modal_card_icono {
    grid-area: icono;
    text-align: center;
}
.package_modal_card_dato1 {
    grid-area: dato1;
    font-size: 14px;
}
.package_modal_card_dato2 {
    grid-area: dato2;
}
.package_modal_card_opcion {
    grid-area: opcion;
    display: flex;
    justify-content: center;
    height: 45px;
}

.package_modal_button{
    text-align: end;
}

@media (max-width: 1000px) {
    .package_modal_container {
        overflow-y: scroll;
    }
}
@media (max-width: 770px) {
    .package_modal_card {
        width:  auto;
    }
    .package_modal_container {
        padding: 20px;
    }
    .package_modal_format {
        display: inline;
    }
    .package_modal_titulo_principal {
        font-size: 25px;
    }
    .package_modal_card_plan_titulo {
        font-size: 18px;
        font-weight: 500;
    }
    .package_modal_card {
        padding: 15px;
        gap: 8px;
    }
    .package_modal_icon_plan {
        width: 75px;
    }
    .package_modal_card_opcion {
        height: 32px;
    }
}