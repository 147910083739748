
.register_sale_ine_rf_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "rfc       rfc    "
                         "fotoIne   fotoRFC";
}

.register_sale_rfc {
    grid-area: rfc;
    margin-bottom: 10px;
}
.register_sale_fotoIne {
    grid-area: fotoIne;
    margin-right: 10px;
}
.register_sale_fotoRfc {
    grid-area: fotoRFC;
    margin-left: 10px;
}

.register_sale_portada {
    position: relative;
}
.register_sale_logo {
    position: absolute;
    top: 56%;
    left: 18%;
    transform: translate(-50%, -50%);
}

.register_sale_image_box {
    justify-content: flex-end;
    padding-right: 100px;
}

.register_sale_logo_box {
    width: 250px;
}

@media ( max-width: 870px ) {
    .register_sale_ine_rf_container {
        grid-template-columns: 1fr;
        grid-template-areas: "rfc    "
                             "fotoIne"
                             "fotoRFC";
    }
    .register_sale_fotoIne {
        margin-right: 0px;
    }
    .register_sale_fotoRfc {
        margin-left: 0px;
    }
    .register_sale_portada {
        position: unset;
    }
    .register_sale_logo {
        margin-top: 20px;
        position: unset;
        top: unset;
        left: unset;
        transform: none
    }
    .register_sale_image_box {
        justify-content: center;
        padding-right: unset;
        text-align: center;
    }
    .register_sale_logo_box {
        width: 100%;
        text-align: center;
    }
}