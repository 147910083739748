
@import '../../../../../../../styles/base/settings';

/*.sales_main {
    padding: 60px 60px 60px 60px;
    //max-width: 1000px;
}*/

.sales_wait {
    height: 666px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sales_section_container_sinPlan p {
    font-size: $font-texto-mediano
}
.sales_section_parrafo {
    padding-bottom: 12px;
    font-size: $font-texto-chico;
}

.sales_plan_container {
    padding: 0px 30px 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
}
.sales_plan_format {
    max-width: 1000px;
    //background-color: aqua;
}

.sales_section_publicar {
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    p {
        font-weight: 500;
        font-size: $font-texto-grande;
        margin-right: 15px;
    }
}

.sales_section {
    margin: 0 auto;
    max-width: 1350px;
    margin-top: 50px;
    margin-bottom: 60px;
}
.sales_section_container_plan {
    display: grid;
    grid-template-columns: 300px 1fr;
}
.sales_section_container_plan_icon {
    padding: 20px 0px 20px 0px;
    text-align: center;
}
.sales_icon_plan {
    width: 300px;
}
.sales_section_container_plan_datos {
    padding: 20px;
}
.sales_section_title {
    font-size: $font-subtitulo-chico;
    padding-bottom: 12px;
}
.sales_section_subtitle {
    font-size: $font-texto-grande;
    padding-bottom: 20px;
}


////////////////////////////////////////////////////////
.sinPlan_section_format {
    display: grid;
    gap: 20px;
    max-width: 800px;
    padding: 25px;
    grid-template-columns: 300px 1fr;
    grid-template-areas: "title     title"
                        "icon       info";
}
.sinPlan_section_title {
    font-size: 35px;
    grid-area: title;
}
.sinPlan_section_icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sinPlan_section_icon_style {
    max-height: 250px;
}
.sinPlan_section_info {
    grid-area: info;
}
.sinPlan_section_info_title {
    margin-bottom: 15px;
    font-size: 25px;
}
////////////////////////////////////////////////////////


@media (max-width: 730px) {
    .sales_plan_container {
        padding: 20px;
    }
    .sales_section_container_plan {
        grid-template-columns: 1fr;
    }
    .sales_section_container_plan_icon {
        padding: 8px 0px 0px 0px;
    }
    .sales_icon_plan {
        width: 150px;
    }
    .sales_section_container_plan_datos {
        padding: 12px 0px 0px 0px;
    }
    .sales_section_title {
        font-size: 16px;
        padding-bottom: 10px;
    }
    .sales_section_subtitle {
        font-size: 12px;
        padding-bottom: 12px;
        font-weight: 500;
    }
    .sales_section_publicar {
        display: block;
        p {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }

    .sinPlan_section_format {
        grid-template-columns: 1fr;
        grid-template-areas: "title"
                             "icon "
                             "info ";
    }
    .sinPlan_section_title {
        font-size: 25px;
    }
    .sinPlan_section_icon_style {
        height: 150px;
    }
    .sinPlan_section_info_title {
        margin-bottom: 10px;
        font-size: 22px;
    }
}

@media (max-width: 600px) {
    .sales_main {
        padding: 25px;
    }
    .sales_section_container_sinPlan {
        margin-bottom: 0px;
    } 
    .sales_section_publicar {
        text-align: center;
        padding-top: 17px;
        padding-bottom: 21px;
    }
    .sales_section_p {
        font-size: 12px;
        padding-bottom: 12px;
    }
}

@media (max-width: 300px) {
    .sales_plan_container {
        padding: 5px;
    }
}